<template>
  <SidebarMenu />
  <div class="content">
    <router-view/>
  </div>
</template>

<script>
import SidebarMenu from "./components/Sidebar/SidebarMenu.vue";
import { mapMutations } from "vuex";


export default {
  name: "App",
  components: {
    SidebarMenu,
  },
  methods: {
    ...mapMutations(["set_darkmode"]),
    isDarkModeEnabled() {
      return (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      );
    },
  },
  created() {
    this.set_darkmode(this.isDarkModeEnabled())
  },
};
</script>

<style>
body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  background-color: var(--bg-color);
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
  color: var(--font-color);
  display: flex;
  flex-direction: row;
}
:root {
  --primary-color: rgb(132, 170, 167);
  --secondary-color: rgb(132, 170, 167);
  --font-color: #424242;
  --bg-color: #fff;
  --highlight-color: #c0110b;
  --light-bg-color: #f1f1f1;
  --lighter-bg-color: #ffffff;

  --text-on-lighter-bg: #424242;
  --text-on-bg: #424242;

  --sidebar-width: 15rem
}

[data-theme="dark"] {
  --primary-color: rgb(92, 117, 115);
  --secondary-color: #818cab;
  --font-color: #bdbdbd;
  --bg-color: #202020;
  --highlight-color: #4d97b9;
  --light-bg-color: #424547;
  --lighter-bg-color: lightgray;
  --text-on-lighter-bg: #202020;
  --text-on-bg: lightgray;
}

.content{
  margin: 0 0 0 var(--sidebar-width);
  height: 100%;
  padding: 0px;
  flex-grow: 1;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 700px) {
  div.content {margin-left: 0;}
  #app{
    flex-direction: column;
  }
}
*:focus {
    outline: none;
}

html,body { height: 100%; margin: 0px; padding: 0px; }

</style>
