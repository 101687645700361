<template>
  <div class="message-container">
    <div :class="{ message: true, in: message.in, out: !message.in }">
      <div v-if="'image' in message">
        <img :src="message.image" alt="" srcset="" />
      </div>
      <p class="message_text">{{ message.string }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["message"],
};
</script>

<style scoped>
.message {
  padding: 0.4em 1em 0.4em 1em;
  background-color: var(--lighter-bg-color);
  border-radius: 30px;
  max-width: 60%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.19);

}
.out {
  margin-left: auto;
}

img {
  max-width: 100%;
  height: auto;
}

.message_text {
  color: var(--text-on-lighter-bg);
  text-align: left;
}
.message-container {
  margin: 0em 2em 1em 1em;
  display: flex;
  flex-direction: column;

  animation: slideInUp;
  animation-duration: 0.5s;
}
</style>