<template>
  <div class="chat">
    <div id="messagge-window">

      <ChatMessage v-for="message in chatmessages" :key="message" :message="message"/>
    </div>
    <form id="message-input-container" @submit.prevent="send_message">
      <input
        type="text"
        id="message-input"
        v-model.trim="current_input"
        :class="{ 'animate__headShake animate__animated': input_animated }"
      />
    </form>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import ChatMessage from "./ChatMessage.vue";

export default {
  data() {
    return {
      current_input: "",
      input_animated: false,
    };
  },
  components:{
      ChatMessage
  },
  methods: {
    ...mapActions(["perform_query"]),
    ...mapMutations(["add_message"]),
    send_message() {
      if (this.current_input.length > 0) {
        this.add_message({ string: this.current_input, in: false });
        console.log(this.chatmessages);
        this.perform_query(this.current_input);

        this.current_input = "";
      } else {
        this.animate_input();
      }
    },
    Sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
    async animate_input() {
      this.input_animated = true;
      await this.Sleep(1000);
      this.input_animated = false;
    },
  },
  computed: {
    chatmessages: {
      get() {
        return this.$store.state.chatmessages;
      },
    },
  },
};
</script>

<style scoped>
.chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}


#messagge-window {
  flex-grow: 1;
  background-color: var(--light-bg-color);
  margin: 2em;
  border-radius: 30px;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.19);
}

#message-input-container {
  padding: 0em 2em 2em 2em;
  width: 100%;
  display: flex;
  flex-direction: row;
}
#message-input {
  flex-grow: 1;
  padding: 12px 20px;
  border-radius: 30px;
  background-color: var(--lighter-bg-color);
  border: 1px solid var(--font-color);
  font-size:14px;
}
.list-enter-active,
.list-leave-active {
  opacity: 1;
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>