<template>
    <router-link :to="link">{{string}}</router-link>
</template>

<script>
export default {
    props: {
        string: String,
        link: String
    }
}
</script>

<style scoped>
a {
  display: block;
  color: var(--font-color);
  padding: 16px;
  text-decoration: none;
  transition: color 0.3s, background-color 1s;
}

a:hover:not(.router-link-active) {
  background-color: var(--bg-color);
  color: var(--font-color);
}

.router-link-active{
    background-color: var(--text-on-bg);
    color: var(--bg-color);
}

@media screen and (max-width: 700px) {
    a {float: left;}
}

@media screen and (max-width: 400px) {
  a {
    text-align: center;
    float: none;
  }
}

</style>